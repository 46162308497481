<template>
  <v-container fluid class="pa-0 ma-0">
    <v-row class="pa-4">
      <v-col cols="12" md="3" class="d-flex align-center justify-center">
        <v-img :src="end.logoCinza" style="height: 5rem !important;" contain></v-img>
      </v-col>
      <v-col cols="12" md="9" class="d-flex align-center justify-end textHeader fontMontserrat" style="gap: 1rem"
        v-if="intBreakPoint >= 2">
        <!-- <p>Pró-Clínica, sua Clínica de Urologia em <span class="textBlue">{{ end.cidade }}</span></p> -->
        <button class="navBtn fontRobotoSlab" @click="scrollTo('especialidades')">
          Especialidades
        </button>
        <button class="navBtn fontRobotoSlab" @click="scrollTo('depoimentos')">
          Depoimentos
        </button>
        <button class="navBtn fontRobotoSlab" @click="scrollTo('localizacao')">
          Localização
        </button>
        <button class="btnHeader textBlue" @click="abrirDialogWhats">Agende sua Consulta</button>
      </v-col>
      <v-col cols="12" class="d-flex align-center justify-space-around textHeader fontMontserrat" v-else>
        <!-- <p>Pró-Clínica, sua Clínica de Urologia em <span class="textBlue">{{ end.cidade }}</span></p> -->
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on">
              <v-icon>mdi-menu</v-icon>
            </v-btn>
          </template>
          <v-list class="d-flex flex-column align-center justify-center">
            <v-list-item @click="scrollTo('especialidades')">
              <span class="navBtn fontRobotoSlab">Especialidades</span>
            </v-list-item>
            <v-list-item @click="scrollTo('depoimentos')">
              <span class="navBtn fontRobotoSlab">Depoimentos</span>
            </v-list-item>
            <v-list-item @click="scrollTo('localizacao')">
              <span class="navBtn fontRobotoSlab">Localização</span>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <v-col cols="12" md="3" class="d-flex align-center justify-center" v-if="intBreakPoint < 2">
        <button class="btnHeader textBlue" @click="abrirDialogWhats">Agende sua Consulta</button>
      </v-col>
    </v-row>
    <v-row :class="intBreakPoint >= 2 ? 'bgHeader pl-16' : 'bgHeader py-16'">
      <v-col cols="12" md="4"
        :class="intBreakPoint < 2 ? 'd-flex align-center justify-center' : 'd-flex align-center justify-center py-0 my-0'">
        <div
          :class="intBreakPoint < 2 ? 'd-flex flex-column align-center justify-center' : 'd-flex flex-column align-start justify-center'"
          style="gap: 1.5rem">
          <h1 class="white--text fontRobotoSlab" style="font-weight: 400; line-height: 1; font-size: 2rem;">Cuide da saúde
            das articulações,</h1>
          <p class="white--text ma-0 pa-0" style="line-height: 1;">ossos, músculos, tendões e
            ligamentos do seu corpo com
            os reumatologistas na
            Pró-Clínica.
          </p>
          <button class="btnCTA" @click="abrirDialogWhats">Agendar minha consulta</button>
        </div>
      </v-col>
      <v-col cols="12" md="8"
        :class="intBreakPoint < 2 ? 'd-flex align-center justify-center pl-8 pr-8' : 'd-flex align-center justify-center py-0 my-0'">
        <v-img src="../../assets/lpsEspecialidade/reumatologia/headerReuma.webp" style="height: 100% !important;"
          contain></v-img>
      </v-col>
    </v-row>

    <v-row :class="intBreakPoint >= 2 ? ' pa-16' : ' py-16'">
      <v-col cols="12" md="6"
        :class="intBreakPoint < 2 ? 'd-flex align-center justify-center pl-8 pr-8' : 'd-flex align-center justify-center'"
        v-if="intBreakPoint >= 2">
        <v-img src="../../assets/lpsEspecialidade/reumatologia/especialista.webp" style="height: 100%;" contain></v-img>
      </v-col>
      <v-col cols="12" md="6"
        :class="intBreakPoint < 2 ? 'd-flex flex-column align-start justify-center pl-8 pr-8' : 'd-flex flex-column align-start justify-center'">
        <h2 class="mediumFont" style=" font-weight: 400; ">
          Atenção aos <span class="textBlue" style="font-weight: 800;"> sintomas!</span>
        </h2>
        <div style="border-bottom:  3px solid #0659A1; height: 0.5rem; width: 10rem;"></div>
        <br />
        <p style="font-weight: 500;">
          <span style="font-weight: 800;">Dor nas Articulações e Músculos:</span> Dor aguda ou crônica em várias partes do
          corpo.
        </p>
        <p style="font-weight: 500;">
          <span style="font-weight: 800;">Inchaço e Rigidez:</span> Inchaço nas articulações, frequentemente acompanhado
          de rigidez matinal ou após períodos de inatividade.
        </p>
        <p style="font-weight: 500;">
          <span style="font-weight: 800;">Fadiga e Cansaço:</span> Sentimento geral de fraqueza.
        </p>
        <p style="font-weight: 500;">
          <span style="font-weight: 800;">Vermelhidão e Calor:</span> Sinais de inflamação, como pele avermelhada e
          sensação de calor ao redor das articulações.
        </p>
        <p style="font-weight: 500;">
          <span style="font-weight: 800;">Limitação de Movimentos:</span> Redução na amplitude de movimento, dificultando
          atividades cotidianas.
        </p>
        <p style="font-weight: 500;">
          <span style="font-weight: 800;">Sintomas Sistêmicos:</span> Febre e/ou perda de peso.
        </p>
        <p style="font-weight: 500;">
          <span style="font-weight: 800;">Sintomas Neurológicos:</span> Dores de cabeça, dormência, formigamento e
          problemas de memória ou concentração.
        </p>
        <button class="btnCTA" @click="abrirDialogWhats">Agendar minha consulta</button>
      </v-col>
    </v-row>

    <v-row class="bgFilas d-flex align-center justify-center mt-16" v-if="intBreakPoint >= 2">
      <v-col cols="12" md="6"
        :class="intBreakPoint < 2 ? 'd-flex align-center justify-center pl-8 pr-8' : 'd-flex align-center justify-center py-8'">
        <p class="white--text fontRobotoSlab" style="font-weight: 400; font-size: 2rem; line-height: 1;">Tudo isso
          <span class="white--text" style="font-weight: 800;">sem
            filas, sem carência e sem
            necessidade de convênio.</span>
        </p>
      </v-col>
    </v-row>

    <v-row class="d-flex align-center justify-center mt-16" v-else>
      <v-col cols="12"
        :class="intBreakPoint < 2 ? 'd-flex align-center justify-center' : 'd-flex align-center justify-center py-8'">
        <p class="white--text fontRobotoSlab" style="font-weight: 400; font-size: 2rem; line-height: 1;"
          v-if="intBreakPoint >= 2">Tudo isso
          <span class="white--text" style="font-weight: 800;">sem
            filas, sem carência e sem
            necessidade de convênio.</span>
        </p>
        <img src="../../assets/lpsEspecialidade/reumatologia/fundoTexto.webp" style="width: 100%; height: auto;" />
      </v-col>
    </v-row>

    <v-row :class="intBreakPoint >= 2 ? 'pt-16' : 'pt-16'" id="especialidades">
      <v-col cols="12" md="12" class="d-flex flex-column align-center justify-center">
        <h3 class="mediumFont" style="font-weight: 400;">
          Veja outras especialidades
        </h3>
        <div style="border-bottom: 3px solid #0659A1; height: 0.5rem; width: 10rem;"></div>
      </v-col>
      <v-row
        :style="intBreakPoint >= 2 ? 'background-color: #eeeded; border-radius: 2rem; margin-top: 10rem; min-height: 40rem' : 'background-color: #eeeded; border-radius: 2rem; margin-top: 2rem; min-height: 40rem'"
        :class="intBreakPoint >= 2 ? 'mx-0 pa-8' : 'mx-0 py-8'">
        <v-col cols="12" md="12"
          :class="intBreakPoint >= 2 ? 'd-flex align-center justify-space-between' : 'd-flex flex-column align-center justify-space-between'"
          :style="intBreakPoint >= 2 ? 'gap: 1rem; margin-top: -15rem' : 'gap: 1rem;'">
          <div class="d-flex flex-column align-center justify-space-around"
            style="background-color: #fff; border-radius: 2rem; width: 100%; min-height: 35rem; box-shadow: 0px 5px 10px 0px #0000007e;  ">
            <img src="../../assets/lpsEspecialidade/reumatologia/ortopedista.webp" style="width: 100%; " />
            <h3 class="">Ortopedista</h3>
            <p :class="intBreakPoint >= 2 ? 'px-4' : 'px-4'" style="font-weight: 500; line-height: 1;">
              Não deixe que dores nas articulações, ossos e músculos limitem sua qualidade de vida. Uma consulta com um
              ortopedista pode ser o primeiro passo para um futuro mais saudável e ativo.
            </p>
            <button class=" btnCTA " @click="abrirDialogWhats">Agendar minha consulta</button>
          </div>
          <div class="d-flex flex-column align-center justify-space-around"
            style="background-color: #fff; border-radius: 2rem; width: 100%; min-height: 35rem; box-shadow: 0px 5px 10px 0px #0000007e; ">
            <img src="../../assets/lpsEspecialidade/reumatologia/dermatologista.webp" style="width: 100%; " />
            <h3 class="">Dermatologista</h3>
            <p :class="intBreakPoint >= 2 ? 'px-4' : 'px-4'" style="font-weight: 500; line-height: 1;">
              Manchas, irritações ou problemas de pele não deveriam roubar sua confiança ou conforto. Uma visita ao
              dermatologista pode ser o início de uma pele mais saudável e radiante.
            </p>
            <button class=" btnCTA " @click="abrirDialogWhats">Agendar minha consulta</button>
          </div>
          <div class="d-flex flex-column align-center justify-space-around"
            style="background-color: #fff; border-radius: 2rem; width: 100%; min-height: 35rem; box-shadow: 0px 5px 10px 0px #0000007e; ">
            <img src="../../assets/lpsEspecialidade/reumatologia/cardiologista.webp" style="width: 100%; " />
            <h3 class="">Cardiologista</h3>
            <p :class="intBreakPoint >= 2 ? 'px-4' : 'px-4'" style="font-weight: 500; line-height: 1;">
              Não permita que palpitações, falta de ar ou cansaço ditem o ritmo do seu coração. Consultar um cardiologista
              pode ser o primeiro passo para um coração mais forte e uma vida mais longa.
            </p>
            <button class=" btnCTA " @click="abrirDialogWhats">Agendar minha consulta</button>
          </div>
        </v-col>
        <v-col cols="12" md="12" class="d-flex flex-column align-center justify-center" v-if="intBreakPoint >= 2">
          <h2 style="font-weight: 400;">Avaliações dos clientes:</h2>
          <div style="border-bottom: 3px solid #0659A1; height: 0.5rem; width: 10rem;"></div>
        </v-col>
      </v-row>
      <v-row :class="intBreakPoint >= 2 ? 'pa-16 mx-0' : 'py-16 mx-0'" id="depoimentos"
        :style="intBreakPoint >= 2 && 'margin-top: -15rem;'">
        <v-col cols="12" md="12" class="d-flex flex-column align-center justify-center" v-if="intBreakPoint < 2">
          <h2 style="font-weight: 400;">Avaliações dos clientes:</h2>
          <div style="border-bottom: 3px solid #0659A1; height: 0.5rem; width: 10rem;"></div>
        </v-col>
        <v-col cols="12" md="12">
          <v-carousel :style="intBreakPoint >= 2 ? 'height: 20rem' : 'height: fit-content;'" hide-delimiter-background
            class="bgDepoimentos">
            <v-carousel-item v-for="depoimento, i in depoimentos" :key="i">
              <div class="d-flex flex-column align-center justify-center pa-16">
                <div class="d-flex align-center justify-center mb-4">
                  <v-icon color="#FFB900" v-for="item, index in 5" :key="index">mdi-star</v-icon>
                </div>
                <p class="white--text">{{ depoimento.text }}</p>
                <h3 class="white--text">{{ depoimento.name }}</h3>
                <img :src="depoimento.image" style="width: 5rem; height: 5rem; border-radius: 50%;" />
              </div>
            </v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>
    </v-row>

    <v-row id="localizacao">
      <v-col cols="12" md="12" class="d-flex flex-column align-center justify-center py-16">
        <h3 style="font-weight: 400;">Venha fazer uma consulta</h3>
        <div style="border-bottom: 3px solid #0659A1; height: 0.5rem; width: 10rem;" class="mb-4"></div>
        <h3>Pró Clínica São José dos Campos</h3>
        <p>R. Maj. Francisco de Paula Elias, 217 - Vila Adyana, São José dos Campos - SP, 12245-320</p>
      </v-col>
    </v-row>

    <v-row style="background-color: #163C70;" class="mx-0">
      <v-col cols="12" md="12" class="d-flex flex-column align-center justify-center py-16">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3667.2826126880395!2d-45.89308362468103!3d-23.196369079052367!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cc4b9c1e4846e9%3A0x42dd4df7c14f6a6c!2zUHLDsyBDbMOtbmljYSBTw6NvIEpvc8OpIGRvcyBDYW1wb3M!5e0!3m2!1spt-BR!2sbr!4v1694194654609!5m2!1spt-BR!2sbr"
          :style="intBreakPoint >= 2 ? 'border:0; width: 50rem; height: 15rem; border-radius: 2rem;' : 'border:0; width: 100%; height: 15rem; border-radius: 2rem;'"
          allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </v-col>
    </v-row>

    <footer>
      <v-row style="background-color: #f5f5f5" class="mx-0">
        <!-- <v-col cols="12" md="12"
          :class="intBreakPoint < 2 ? 'd-flex align-center justify-center pl-8 pr-8' : 'd-flex align-center justify-center'">
          <a href="https://abaremarketing.com/" target="_blank">
            <v-img style="max-width: 3rem" src="@/assets/abareLogoPreto.webp" contain></v-img>
          </a>
          <router-link to="/">
            <v-img style="max-width: 8rem" :src="end.logoPreto" contain></v-img>
          </router-link>
        </v-col> -->
        <v-col cols="12" md="4" class="d-flex align-center justify-center">
          <span>
            Copyright © 2023
            <router-link to="/">
              Pró-Clinica.
            </router-link>
            Todos os direitos reservados.
          </span>
        </v-col>
        <v-col cols="12" md="4" offset-md="4" class="d-flex align-center justify-center">
          <span>
            Desenvolvido por:
          </span>
          <v-img style="max-width: 3rem" src="@/assets/abareLogoPreto.webp" contain></v-img>
        </v-col>
      </v-row>
    </footer>

  </v-container>
</template>

<script>
import { RouterLink } from 'vue-router';
import pessoa1 from '../../assets/lpsEspecialidade/reumatologia/pessoa1.webp';
import pessoa2 from '../../assets/lpsEspecialidade/reumatologia/pessoa2.webp';
import pessoa3 from '../../assets/lpsEspecialidade/reumatologia/pessoa3.webp';

export default {
  name: 'Urologia',
  data() {
    return {
      items: [
        "Dor ou desconforto persistente na região lombar ou abdominal.", "Dificuldade ou dor ao urinar.", "Sangue na urina ou alterações na cor da urina.", "Dificuldade em manter a ereção ou outros problemas de saúde sexual.", "Histórico familiar de doenças urológicas."
      ],
      depoimentos: [
        {
          text: "O local é super confortável, médicos de qualidade, passei minha filha na pediatra e gostei muito da conduta médica. Com certeza voltarei a passar lá.",
          name: "Sarah Kelly",
          image: pessoa1
        },
        {
          text: "Quero agradecer ao Dr. Helberth, cirurgião-dentista excelente atedimento e altamente competente. E, agradeço também todas as meninas da equipe Pró-clínica sempre dispostas a ajudar e atender da melhor forma.",
          name: "Fernanda Lomeu",
          image: pessoa2
        },
        {
          text: "Gostaria de elogiar a toda equipe, e ao médico especialista com quem me consultei Dr. Bruno Azevedo o atendimento foi rápido pontual e bem esclarecedor me mostrou imagens o que era como surgia foi muito didático, explicou a medição, muitíssimo obrigado a todos vocês.",
          name: "Antonio Santos",
          image: pessoa3
        }
      ]
    };
  },
  methods: {
    abrirDialogWhats() {
      this.$root.$emit('show-whats');
    },
    scrollTo(el) {
      const element = document.getElementById(el);
      element.scrollIntoView({ behavior: 'smooth' });
    }
  },
  computed: {
    intBreakPoint() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 0;
        case 'sm':
          return 1;
        case 'md':
          return 2;
        case 'lg':
          return 3;
        case 'xl':
          return 4;
        default:
          return 0;
      }
    },
    end() {
      return this.$store.getters.unidade;
    },
  },
  components: { RouterLink }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  font-family: Roboto, sans-serif !important;
  color: #626262;
}

.fontRobotoSlab {
  font-family: 'Roboto Slab', Roboto, serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto Slab', Roboto, serif !important;
}

@media (max-width: 600px) {
  .mediumFont {
    font-size: 1.2rem !important
  }

  h1 {
    line-height: 2rem !important;
    font-size: 1.4rem !important;
  }

  .row {
    margin-left: 5vw !important;
    margin-right: 5vw !important;
  }

  .navBtn {
    font-size: 1rem !important;
    font-weight: 400;
  }

  .btnCTA {
    width: fit-content !important;
    font-size: 0.8rem;
  }
}

@media (min-width: 1920px) {
  h1 {
    font-size: 2rem !important;
  }
}

h1 {
  font-size: 1.5rem;
}

.row {
  margin-left: 10vw;
  margin-right: 10vw;
}

.mediumFont {
  font-size: 1.5rem
}

.textHeader {
  font-size: 1.3rem;
  font-weight: 700;
  color: #000;
  text-align: center;
  margin: 0;
  padding: 0;
}

.textBlue {
  color: #085A9E !important;
}

.textBlue2 {
  color: #8FCCFF;
}

.textGray {
  color: #817F7E
}

.bgBlue {
  background-color: #085A9E;
}

.bgDepoimentos {
  background-color: #085A9E;
  border-radius: 2rem;
}

.bgHeader {
  background-color: #0659A1;
  border-radius: 2rem;
}

.btnHeader {
  padding: 0.5rem 1rem;
  font-weight: 700;
  border: 2px solid #0659A1;
  border-radius: 2rem;
}

.btnHeader:hover,
.btnHeader:active {
  background-color: #0659A1;
  color: #fff !important;
  padding: 0.5rem 1rem;
  font-weight: 700;
}

.navBtn {
  font-size: 1.3rem;
  font-weight: 400;
}

.navBtn:hover,
.navBtn:active {
  color: #0659A1;
}

.btnCTA {
  background-color: #25D366;
  color: #fff;
  width: fit-content;
  padding: 1rem 2rem;
  border-radius: 2rem;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.1rem;
}

.details {
  /* border: 1px solid #ccc; */
  margin: 0;
  width: 100% !important;
  height: 4rem;
  /* box-shadow: rgb(0, 0, 0, 0.3) 0px 3px 0; */
}

.bgFilas {
  background-image: url('../../assets/lpsEspecialidade/reumatologia/fundo1.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 10rem;
  margin: 0;
}

li {
  list-style: none;
  margin: 0;
  padding: 0;
}
</style>